<template>
  <div class="anthro-result-table">
    <a-table :scroll="{ y: 350 }" :columns="columns" :data-source="tableData" :pagination="false" size="small"/>
  </div>
</template>

<script>

export default {
  name: 'AnthropometryResultsTable',
  props: {
    type: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    filterType: {
      type: String,
      required: true
    },
    table: {
      type: Array,
      required: true
    }
  },
  computed: {
    tableData() {
      if (this.group === 'cluster') {
        if (this.type === 'msd') {
          return this.table.map((data, index) => ({
            key: index + 1,
            cluster: data.cluster,
            clusterValue: `Cluster ${data.cluster}`,
            teamIds: data.team && data.team.split(',').map(team => `Team ${team}`).join(',\n'),
            xbar: data.xbar,
            sd: `± ${data.sd}`,
            n: data.n
          }));
        }
        if (this.type === 'iod') {
          return this.table.map((data, index) => ({
            key: index + 1,
            flag: data.flag,
            iod: data.iod,
            pval: data.pval ? data.pval.replace('p', '').replace('=', '') : '-'
          }));
        }
        if (this.type === 'caseDistribution') {
          return this.table.map((data, index) => ({
            key: index + 1,
            bin: data.bin,
            n: data.n,
            clusters: data.clusters || '-'
          }));
        }
      }
      if (this.group === 'months') {
        if (this.type === 'msd') {
          return this.table.map((data, index) => ({
            key: index + 1,
            agecat: data.agecat,
            xbar: data.xbar,
            sd: `± ${data.sd}`,
            n: data.n
          }));
        }
      }
      return this.table;
    },
    columns() {
      try {
        return this.allColumns(this.filterType)[this.group][this.type];
      } catch (e) {
        return [];
      }
    }
  },
  methods: {
    allColumns(filterType) {
      return {
        cluster: {
          msd: [
            {
              title: this.$t('components.labels.clusterId'),
              dataIndex: 'clusterValue',
              key: 'cluster',
              sorter: (a, b) => a.cluster - b.cluster
            },
            {
              title: this.$t('components.labels.teamId'),
              dataIndex: 'teamIds',
              key: 'team'
            },
            {
              title: filterType === 'muac' ? this.$t('components.labels.meanMM', {}).toUpperCase() : this.$t('components.labels.meanZScore', {}).toUpperCase(),
              dataIndex: 'xbar',
              key: 'xbar',
              sorter: (a, b) => parseFloat(a.xbar) - parseFloat(b.xbar)
            },
            {
              title: this.$t('components.labels.sd'),
              dataIndex: 'sd',
              key: 'sd'
            },
            {
              title: this.$t('components.labels.numberOfChildren'),
              dataIndex: 'n',
              key: 'n'
            }
          ],
          iod: [
            {
              title: this.$t('components.labels.indicator'),
              dataIndex: 'flag',
              key: 'flag'
            },
            {
              title: this.$t('components.labels.indexOfDispersion'),
              dataIndex: 'iod',
              key: 'iod'
            },
            {
              title: this.$t('components.labels.pValueForPoisson'),
              dataIndex: 'pval',
              key: 'pval'
            }
          ],
          caseDistribution: [
            {
              title: this.$t('components.labels.numberOfMalnourishedCases'),
              dataIndex: 'bin',
              key: 'bin'
            },
            {
              title: this.$t('components.labels.clusterCount'),
              dataIndex: 'n',
              key: 'n'
            },
            {
              title: this.$t('components.labels.clusterId'),
              dataIndex: 'clusters',
              key: 'clusters'
            }
          ]
        },
        months: {
          msd: [
            {
              title: this.$t('components.labels.ageCategory'),
              dataIndex: 'agecat',
              key: 'agecat'
            },
            {
              title: filterType === 'muac' ? this.$t('components.labels.meanMM', {}).toUpperCase() : this.$t('components.labels.meanZScore', {}).toUpperCase(),
              dataIndex: 'xbar',
              key: 'xbar',
              sorter: (a, b) => parseFloat(a.xbar) - parseFloat(b.xbar)
            },
            {
              title: this.$t('components.labels.sd'),
              dataIndex: 'sd',
              key: 'sd'
            },
            {
              title: this.$t('components.labels.numberOfChildren'),
              dataIndex: 'n',
              key: 'n'
            }
          ]
        }
      };
    }
  }
};
</script>

<style lang="scss">
.anthro-result-table {

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-body table {
    border-top: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
  }

  .ant-table-thead {
    background-color: #f5f5f5;
  }

  .ant-table-row {
    border-bottom: 1px solid #e8e8e8 !important;
  }

  .ant-table-small {
    border: none !important;
  }
}
</style>
